import React, { useEffect, useState } from "react";

import cls from "./GalleryComp.module.css";

import img1 from "../../assets/Gallery/1.jpg";
import img2 from "../../assets/Gallery/2.jpg";
import img3 from "../../assets/Gallery/3.jpg";
import img4 from "../../assets/Gallery/4.jpg";
import img5 from "../../assets/Gallery/5.jpg";
import img6 from "../../assets/Gallery/6.jpg";
import img7 from "../../assets/Gallery/7.jpg";
import img8 from "../../assets/Gallery/8.jpg";
import img9 from "../../assets/Gallery/9.jpg";
import img10 from "../../assets/Gallery/10.jpg";
import img11 from "../../assets/Gallery/11.jpg";
import img12 from "../../assets/Gallery/12.jpg";
import img13 from "../../assets/Gallery/13.jpg";
import img14 from "../../assets/Gallery/14.jpg";
import img15 from "../../assets/Gallery/15.jpg";
import img16 from "../../assets/Gallery/16.jpg";
import img17 from "../../assets/Gallery/17.jpg";
import img18 from "../../assets/Gallery/18.jpg";
import img19 from "../../assets/Gallery/19.jpg";
import img20 from "../../assets/Gallery/20.jpg";
import img21 from "../../assets/Gallery/21.jpg";
import img22 from "../../assets/Gallery/22.jpg";
import img23 from "../../assets/Gallery/23.jpg";
import img24 from "../../assets/Gallery/24.jpg";
import img25 from "../../assets/Gallery/25.jpg";
import img26 from "../../assets/Gallery/26.jpg";
import img27 from "../../assets/Gallery/27.jpg";
import img28 from "../../assets/Gallery/28.jpg";
import img29 from "../../assets/Gallery/29.jpg";
import img30 from "../../assets/Gallery/30.jpg";
import img31 from "../../assets/Gallery/31.jpg";
import img32 from "../../assets/Gallery/32.jpg";
import img33 from "../../assets/Gallery/33.jpg";
import img34 from "../../assets/Gallery/34.jpg";
import img35 from "../../assets/Gallery/35.jpg";
import img36 from "../../assets/Gallery/36.jpg";
import img37 from "../../assets/Gallery/37.jpg";
import img38 from "../../assets/Gallery/38.jpg";
import img39 from "../../assets/Gallery/39.jpg";
import img40 from "../../assets/Gallery/40.jpg";
// import img41 from "../../assets/Gallery/41.jpg";
// import img42 from "../../assets/Gallery/42.jpg";
// import img43 from "../../assets/Gallery/43.jpg";
// import img44 from "../../assets/Gallery/44.jpg";
// import img45 from "../../assets/Gallery/45.jpg";
// import img46 from "../../assets/Gallery/46.jpg";
// import img47 from "../../assets/Gallery/47.jpg";
// import img48 from "../../assets/Gallery/48.jpg";
// import img49 from "../../assets/Gallery/49.jpg";
// import img50 from "../../assets/Gallery/50.jpg";
// import img51 from "../../assets/Gallery/51.jpg";
// import img52 from "../../assets/Gallery/52.jpg";

const GalleryComp = () => {
  const imgs = [
    img1,
    img2,
    img3,
    img4,
    img5,
    img6,
    img7,
    img8,
    // img9,
    // img10,
    // img11,
    // img12,
    // img13,
    // img14,
    // img15,
    // img16,
    // img17,
    // img18,
    // img19,
    // img20,
    // img21,
    // img22,
    // img23,
    // img24,
    // img25,
    // img26,
    // img27,
    // img28,
    // img29,
    // img30,
    // img31,
    // img32,
    // img33,
    // img34,
    // img35,
    // img36,
    // img37,
    // img38,
    // img39,
    // img40,
  ];

  return (
    <div className="container">
      <div className="row">
        <h1 className={cls.smallHeading}>
          <span className={cls.heading}>
            {/* Gallery */}
            गॅलरी
            </span>
        </h1>
      </div>
      <div className="row">
        {imgs.map((img, index) => (
          <div
            key={index}
            className="item col-lg-3 col-md-4 col-6 col-sm"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div className={cls.galleryCompImgContainer}>
              <img
                src={img}
                alt={`Gallery item ${index + 1}`}
                className={`img-fluid ${cls.galleryCompImg}`}
                loading={"lazy"}
              />
            </div>
          </div>
        ))}
      </div>
      <div
        style={{
          padding: "2rem 0",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          flexWrap: "wrap",
          gap: "2rem",
        }}
      >
        <a
          href={"https://photos.app.goo.gl/nMv3GLf9tkAkcTs48"}
          target="_blank"
          rel="noreferrer"
        >
          <button className="btn btn-success">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15Z"
                fill="white"
              />
              <path
                d="M23.205 11.745C22.3229 9.46324 20.7915 7.48996 18.8001 6.06906C16.8087 4.64817 14.4446 3.84193 12 3.75C9.55539 3.84193 7.19129 4.64817 5.1999 6.06906C3.20851 7.48996 1.67712 9.46324 0.795001 11.745C0.735427 11.9098 0.735427 12.0902 0.795001 12.255C1.67712 14.5368 3.20851 16.51 5.1999 17.9309C7.19129 19.3518 9.55539 20.1581 12 20.25C14.4446 20.1581 16.8087 19.3518 18.8001 17.9309C20.7915 16.51 22.3229 14.5368 23.205 12.255C23.2646 12.0902 23.2646 11.9098 23.205 11.745ZM12 16.875C11.0358 16.875 10.0933 16.5891 9.2916 16.0534C8.48991 15.5177 7.86507 14.7564 7.49609 13.8656C7.12711 12.9748 7.03057 11.9946 7.21867 11.0489C7.40678 10.1033 7.87108 9.23464 8.55286 8.55285C9.23464 7.87107 10.1033 7.40677 11.0489 7.21867C11.9946 7.03057 12.9748 7.12711 13.8656 7.49609C14.7564 7.86506 15.5177 8.48991 16.0534 9.2916C16.5891 10.0933 16.875 11.0358 16.875 12C16.873 13.2923 16.3588 14.5311 15.445 15.445C14.5311 16.3588 13.2923 16.873 12 16.875Z"
                fill="white"
              />
            </svg>
            {/* Event Preparations */}
            शिवसाहित्य संमेलन पूर्वतयारीचे क्षण
          </button>
        </a>
      </div>
      {/* <div
        style={{
          padding: "1.5rem 0",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          flexWrap: "wrap",
          gap: "3rem",
        }}
      >
        <a
          href={
            "https://drive.google.com/drive/folders/1gXZ-cmBQh0sg24SMzW1hzTv3hF_uu-wI?usp=sharing"
          }
          target="_blank"
          rel="noreferrer"
        >
          <button className="btn btn-success">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15Z"
                fill="white"
              />
              <path
                d="M23.205 11.745C22.3229 9.46324 20.7915 7.48996 18.8001 6.06906C16.8087 4.64817 14.4446 3.84193 12 3.75C9.55539 3.84193 7.19129 4.64817 5.1999 6.06906C3.20851 7.48996 1.67712 9.46324 0.795001 11.745C0.735427 11.9098 0.735427 12.0902 0.795001 12.255C1.67712 14.5368 3.20851 16.51 5.1999 17.9309C7.19129 19.3518 9.55539 20.1581 12 20.25C14.4446 20.1581 16.8087 19.3518 18.8001 17.9309C20.7915 16.51 22.3229 14.5368 23.205 12.255C23.2646 12.0902 23.2646 11.9098 23.205 11.745ZM12 16.875C11.0358 16.875 10.0933 16.5891 9.2916 16.0534C8.48991 15.5177 7.86507 14.7564 7.49609 13.8656C7.12711 12.9748 7.03057 11.9946 7.21867 11.0489C7.40678 10.1033 7.87108 9.23464 8.55286 8.55285C9.23464 7.87107 10.1033 7.40677 11.0489 7.21867C11.9946 7.03057 12.9748 7.12711 13.8656 7.49609C14.7564 7.86506 15.5177 8.48991 16.0534 9.2916C16.5891 10.0933 16.875 11.0358 16.875 12C16.873 13.2923 16.3588 14.5311 15.445 15.445C14.5311 16.3588 13.2923 16.873 12 16.875Z"
                fill="white"
              />
            </svg>
            7/12/23
          </button>
        </a>

        <a
          href={
            "https://drive.google.com/drive/folders/1-GsYUqxd97PqlHFjRhCN10q1TZ9DJF9z?usp=sharing"
          }
          target="_blank"
          rel="noreferrer"
        >
          <button className="btn btn-success">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15Z"
                fill="white"
              />
              <path
                d="M23.205 11.745C22.3229 9.46324 20.7915 7.48996 18.8001 6.06906C16.8087 4.64817 14.4446 3.84193 12 3.75C9.55539 3.84193 7.19129 4.64817 5.1999 6.06906C3.20851 7.48996 1.67712 9.46324 0.795001 11.745C0.735427 11.9098 0.735427 12.0902 0.795001 12.255C1.67712 14.5368 3.20851 16.51 5.1999 17.9309C7.19129 19.3518 9.55539 20.1581 12 20.25C14.4446 20.1581 16.8087 19.3518 18.8001 17.9309C20.7915 16.51 22.3229 14.5368 23.205 12.255C23.2646 12.0902 23.2646 11.9098 23.205 11.745ZM12 16.875C11.0358 16.875 10.0933 16.5891 9.2916 16.0534C8.48991 15.5177 7.86507 14.7564 7.49609 13.8656C7.12711 12.9748 7.03057 11.9946 7.21867 11.0489C7.40678 10.1033 7.87108 9.23464 8.55286 8.55285C9.23464 7.87107 10.1033 7.40677 11.0489 7.21867C11.9946 7.03057 12.9748 7.12711 13.8656 7.49609C14.7564 7.86506 15.5177 8.48991 16.0534 9.2916C16.5891 10.0933 16.875 11.0358 16.875 12C16.873 13.2923 16.3588 14.5311 15.445 15.445C14.5311 16.3588 13.2923 16.873 12 16.875Z"
                fill="white"
              />
            </svg>
            8/12/23
          </button>
        </a>

        <a
          href={
            "https://drive.google.com/drive/folders/1-GnpHYqJUxRoUh3siaHxErFZTHtSslQz?usp=sharing"
          }
          target="_blank"
          rel="noreferrer"
        >
          <button className="btn btn-success">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15Z"
                fill="white"
              />
              <path
                d="M23.205 11.745C22.3229 9.46324 20.7915 7.48996 18.8001 6.06906C16.8087 4.64817 14.4446 3.84193 12 3.75C9.55539 3.84193 7.19129 4.64817 5.1999 6.06906C3.20851 7.48996 1.67712 9.46324 0.795001 11.745C0.735427 11.9098 0.735427 12.0902 0.795001 12.255C1.67712 14.5368 3.20851 16.51 5.1999 17.9309C7.19129 19.3518 9.55539 20.1581 12 20.25C14.4446 20.1581 16.8087 19.3518 18.8001 17.9309C20.7915 16.51 22.3229 14.5368 23.205 12.255C23.2646 12.0902 23.2646 11.9098 23.205 11.745ZM12 16.875C11.0358 16.875 10.0933 16.5891 9.2916 16.0534C8.48991 15.5177 7.86507 14.7564 7.49609 13.8656C7.12711 12.9748 7.03057 11.9946 7.21867 11.0489C7.40678 10.1033 7.87108 9.23464 8.55286 8.55285C9.23464 7.87107 10.1033 7.40677 11.0489 7.21867C11.9946 7.03057 12.9748 7.12711 13.8656 7.49609C14.7564 7.86506 15.5177 8.48991 16.0534 9.2916C16.5891 10.0933 16.875 11.0358 16.875 12C16.873 13.2923 16.3588 14.5311 15.445 15.445C14.5311 16.3588 13.2923 16.873 12 16.875Z"
                fill="white"
              />
            </svg>
            9/12/23
          </button>
        </a>

        <a
          href={
            "https://drive.google.com/drive/folders/1-CKwsYKO97gme90sXKNtjefXKmS_YIJ5?usp=sharing"
          }
          target="_blank"
          rel="noreferrer"
        >
          <button className="btn btn-success">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15Z"
                fill="white"
              />
              <path
                d="M23.205 11.745C22.3229 9.46324 20.7915 7.48996 18.8001 6.06906C16.8087 4.64817 14.4446 3.84193 12 3.75C9.55539 3.84193 7.19129 4.64817 5.1999 6.06906C3.20851 7.48996 1.67712 9.46324 0.795001 11.745C0.735427 11.9098 0.735427 12.0902 0.795001 12.255C1.67712 14.5368 3.20851 16.51 5.1999 17.9309C7.19129 19.3518 9.55539 20.1581 12 20.25C14.4446 20.1581 16.8087 19.3518 18.8001 17.9309C20.7915 16.51 22.3229 14.5368 23.205 12.255C23.2646 12.0902 23.2646 11.9098 23.205 11.745ZM12 16.875C11.0358 16.875 10.0933 16.5891 9.2916 16.0534C8.48991 15.5177 7.86507 14.7564 7.49609 13.8656C7.12711 12.9748 7.03057 11.9946 7.21867 11.0489C7.40678 10.1033 7.87108 9.23464 8.55286 8.55285C9.23464 7.87107 10.1033 7.40677 11.0489 7.21867C11.9946 7.03057 12.9748 7.12711 13.8656 7.49609C14.7564 7.86506 15.5177 8.48991 16.0534 9.2916C16.5891 10.0933 16.875 11.0358 16.875 12C16.873 13.2923 16.3588 14.5311 15.445 15.445C14.5311 16.3588 13.2923 16.873 12 16.875Z"
                fill="white"
              />
            </svg>
            10/12/23
          </button>
        </a>
      </div> */}
    </div>
  );
};

export default GalleryComp;
