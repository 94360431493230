import React from "react";

import QR from "../assets/officeQR.jpg";
import Footer from "../components/Footer/Footer";
import Header from "../components/Header/Header";
import cls from "./Office.module.css";

const Office = () => {
  return (
    <>
      <Header />
      <div className={"container"}>
        <h1 style={{ paddingTop: "2rem" }}>Contact Us</h1>
        <div className={"row"}>
          <div className={`col-12 col-md-4`}>
            <div className={`${cls.contactBox}`}>
              <h5
                style={{
                  padding: "0 0 0.5rem 0",
                  display: "flex",
                  justifyContent: "center",
                  textDecoration: "underline",
                }}
              >
                Phone
              </h5>
              <p>Telephone: 022-24373754</p>
              <p>Telephone: 022-24313938</p>
            </div>
          </div>
          <div className={`col-12 col-md-4`}>
            <div className={`${cls.contactBox}`}>
              <h5
                style={{
                  padding: "0 0 0.5rem 0",
                  display: "flex",
                  justifyContent: "center",
                  textDecoration: "underline",
                }}
              >
                Mail
              </h5>
              <p>Email: abvpkendra@gmail.com</p>
            </div>
          </div>
          <div className={`col-12 col-md-4`}>
            <div className={`${cls.contactBox}`}>
              <h5
                style={{
                  padding: "0 0 0.5rem 0",
                  display: "flex",
                  justifyContent: "center",
                  textDecoration: "underline",
                }}
              >
                Chat
              </h5>
              <p>
                WhatsApp: 7838009404
                <br />
                (Vishal Sharma)
              </p>
            </div>
          </div>
        </div>

        <div className={"row"}>
          <div className={`col-12 col-md-4`}>
            <div className={`${cls.contactBox}`}>
              <h5
                style={{
                  padding: "0 0 0.5rem 0",
                  display: "flex",
                  justifyContent: "center",
                  textDecoration: "underline",
                }}
              >
                Office
              </h5>
              <a
                href={"https://maps.app.goo.gl/3GtKC4kaSCwtNVkL6"}
                target="_blank"
                rel="noreferrer"
                style={{
                  padding: "3rem 0 0.5rem 0",
                  display: "flex",
                  justifyContent: "center",
                  textDecoration: "underline",
                }}
              >
                <button className="btn btn-lg btn-success">View Map</button>
              </a>
            </div>
          </div>
          <div className={`col-12 col-md-4`}>
            <div className={`${cls.contactBox}`}>
              <h5
                style={{
                  padding: "0 0 0.5rem 0",
                  display: "flex",
                  justifyContent: "center",
                  textDecoration: "underline",
                }}
              >
                Address
              </h5>
              <p>Indraprastha Nagar,</p>
              <p>DDA Ground, </p>
              <p>Burari,</p>
              <p>Delhi, 110033 </p>
            </div>
          </div>
          {/* <div className={`col-12 col-md-4`}>
            <div className={`${cls.contactBox}`}>
              <h5
                style={{
                  padding: "0 0 0.5rem 0",
                  display: "flex",
                  justifyContent: "center",
                  textDecoration: "underline",
                }}
              >
                Timing and Details
              </h5>
              Details
            </div>
          </div> */}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Office;
