import React, { useRef, useState, useEffect } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import SwiperCore, { Autoplay, Pagination, Navigation } from "swiper/modules";
import news1 from "../assets/news1.jpg";
import news2 from "../assets/news2.jpg";
import news3 from "../assets/news3.jpg";
import news4 from "../assets/news4.jpg";
import news5 from "../assets/news8-1.jpg";
import news6 from "../assets/news8-2.jpg";
import Footer from "../components/Footer/Footer";
import Header from "../components/Header/Header";
import styles from "./Media.module.css";

const logos = [
  { id: 1, logo: news1, alt: "Logo 1" },
  { id: 2, logo: news2, alt: "Logo 2" },
  { id: 3, logo: news3, alt: "Logo 3" },
  { id: 4, logo: news4, alt: "Logo 4" },
  { id: 5, logo: news5, alt: "Logo 5" },
  { id: 6, logo: news6, alt: "Logo 6" },
];

const imgs = [news1, news2, news3, news4, news5, news6];

function Media() {
  // const [width, setWidth] = useState(window.innerWidth);

  // useEffect(() => {
  //   const handleResize = () => {
  //     setWidth(window.innerWidth);
  //   };

  //   window.addEventListener("resize", handleResize);

  //   return () => {
  //     window.removeEventListener("resize", handleResize);
  //   };
  // }, []);
  return (
    <>
      <Header />
      <h1 className={styles.smallHeading}>
        <span className={styles.heading}>
          {/* Media News */}
          मीडिया न्यूज
        </span>
      </h1>
      <div className={styles.swiperContainer}>
        <Swiper
          spaceBetween={30}
          slidesPerView={1}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          pagination={{
            clickable: true,
          }}
          navigation={true}
          modules={[Autoplay, Pagination, Navigation]}
          loop={true}
          className={styles.mySwiper}
        >
          {logos.map((logo) => (
            <SwiperSlide key={logo.id} className={styles.swiperSlide}>
              <div className={styles.swiperImageContainer}>
                <img
                  src={logo.logo}
                  alt={logo.alt}
                  className={styles.swiperImage}
                />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      <div className="container">
        <div className="row">
          {imgs.map((img, index) => (
            <div
              key={index}
              className="item col-lg-3 col-md-4 col-6 col-sm"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div className={styles.galleryCompImgContainer}>
                <img
                  src={img}
                  alt={`Gallery item ${index + 1}`}
                  className={`img-fluid ${styles.galleryCompImg}`}
                  loading={"lazy"}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Media;
