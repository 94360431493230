import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import { AuthProvider } from "./context/AuthContext";

import ProtectedRoute from "./ProtectedRoute";

import "./App.css";

import Landing from "./pages/Landing";
import Login from "./pages/Login";
import Timetable from "./pages/Timetable";
import Arrangements from "./pages/Arrangements";
import Feedback from "./pages/Feedback";
import Gallery from "./pages/Gallery";
import Media from "./pages/Media";
import Office from "./pages/Office";
import Social from "./pages/Social";
import SuggestionsForResolution from "./pages/SuggestionsForResolution";
import Suggestions from "./pages/Suggestions";
import BookStall from "./pages/BookStall";
import RepresentativeForm from "./pages/RepresentativeForm";
import Essay from "./pages/Essay";

function App() {
  return (
    <div className="App">
      <AuthProvider>
        <Router>
          <Routes>
            <Route path="/" exact element={<Landing />} />
            <Route path="/login" exact element={<Login />} />
            <Route
              path="/timetable"
              exact
              element={
                // <ProtectedRoute>
                <Timetable />
                // </ProtectedRoute>
              }
            />

            <Route
              path="/arrangements"
              exact
              element={
                // <ProtectedRoute>
                <Arrangements />
                // </ProtectedRoute>
              }
            />

            <Route
              path="/feedback"
              exact
              element={
                // <ProtectedRoute>
                <Feedback />
                // </ProtectedRoute>
              }
            />
            <Route
              path="/gallery"
              exact
              element={
                // <ProtectedRoute>
                <Gallery />
                // </ProtectedRoute>
              }
            />
            <Route
              path="/media"
              exact
              element={
                // <ProtectedRoute>
                <Media />
                // </ProtectedRoute>
              }
            />
            <Route
              path="/office"
              exact
              element={
                // <ProtectedRoute>
                <Office />
                // </ProtectedRoute>
              }
            />
            <Route
              path="/social"
              exact
              element={
                // <ProtectedRoute>
                <Social />
                // </ProtectedRoute>
              }
            />
            <Route
              path="/resolution"
              element={
                // <ProtectedRoute>
                <SuggestionsForResolution />
                // </ProtectedRoute>
              }
            />
            <Route
              path="/suggestions"
              element={
                // <ProtectedRoute>
                <Suggestions />
                // </ProtectedRoute>
              }
            />
            <Route
              path="/bookstall"
              exact
              element={
                // <ProtectedRoute>
                <BookStall />
                // </ProtectedRoute>
              }
            />
            <Route
              path="/representative"
              exact
              element={
                // <ProtectedRoute>
                <RepresentativeForm />
                // </ProtectedRoute>
              }
            />
            <Route
              path="/essay"
              exact
              element={
                // <ProtectedRoute>
                <Essay />
                // </ProtectedRoute>
              }
            />
          </Routes>
        </Router>
      </AuthProvider>
    </div>
  );
}

export default App;
