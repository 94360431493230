import React, { useState } from "react";
import { Link } from "react-router-dom";

import leftLogo from "../../assets/prabhodhan.png";
import rightLogo from "../../assets/homepgimg.jpg";
import logo from "../../assets/logo.jpg";
import cls from "./Header.module.css";

const Header = () => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [dropdownPosition, setDropdownPosition] = useState({ top: 0, left: 0 });

  const toggleDropdown = (event) => {
    // Calculate dropdown position based on button coordinates
    const buttonRect = event.currentTarget.getBoundingClientRect();
    setDropdownPosition({
      top: buttonRect.bottom,
      left: buttonRect.left,
    });
    setShowDropdown(!showDropdown);
  };

  return (
    <>
      <section className="" style={{ backgroundColor: "#6E312E" }}>
        <div className="container">
          <nav className="navbar navbar-expand-lg">
            <div className="container-fluid">
              <Link to="/" className="navbar-brand" href="#">
                <img src={leftLogo} alt="logo" height="70px" width="70px" style={{backgroundColor: "#f4dfb6", padding: "2px", borderRadius: "10px"}} />
              </Link>
              <Link to="/" className="navbar-brand" href="#">
                <img src={logo} alt="logo" width="70px" style={{backgroundColor: "#f4dfb6", padding: "2px", borderRadius: "10px"}} />
              </Link>
              <Link to="/" className="navbar-brand" href="#">
                <img src={rightLogo} alt="logo" width="70px" style={{backgroundColor: "#f4dfb6", padding: "2px", borderRadius: "10px"}} />
              </Link>
              <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarNav"
                aria-controls="navbarNav"
                aria-expanded="false"
                aria-label="Toggle navigation"
                onClick={toggleDropdown}
              >
                <span className="navbar-toggler-icon"></span>
              </button>
              <div
                className={`navbar-collapse collapse ${cls.navbarFont}`}
                id="navbarNav"
              >
                <ul className="navbar-nav ms-auto">
                  <li className="nav-item">
                    <Link
                      to="/"
                      className={`nav-link ${cls.hoverUnderline}`}
                      href="#"
                    >
                      {/* Home */}
                      मुख्यपृष्ठ
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to="/timetable"
                      className={`nav-link ${cls.hoverUnderline}`}
                      href="#"
                    >
                      {/* Timetable */}
                      वेळापत्रक
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to="/arrangements"
                      className={`nav-link ${cls.hoverUnderline}`}
                      href="#"
                    >
                      {/* Arrangements */}
                      व्यवस्था
                    </Link>
                  </li>
                  {/* <li className="nav-item">
                    <Link
                      to="/office"
                      className={`nav-link ${cls.hoverUnderline}`}
                      href="#"
                    >
                      Office
                    </Link>
                  </li> */}
                  <li className="nav-item">
                    <Link
                      to="/social"
                      className={`nav-link ${cls.hoverUnderline}`}
                      href="#"
                    >
                      {/* Social Media */}
                      सोशल मीडिया
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to="/media"
                      className={`nav-link ${cls.hoverUnderline}`}
                      href="#"
                    >
                      {/* Media News */}
                      मीडिया न्यूज
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to="/gallery"
                      className={`nav-link ${cls.hoverUnderline}`}
                      href="#"
                    >
                      {/* Gallery */}
                      गॅलरी
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to="/suggestions"
                      className={`nav-link ${cls.hoverUnderline}`}
                      href="#"
                      style={{paddingRight: "10px"}}
                    >
                      {/* Resolutions */}
                      ठराव
                    </Link>
                  </li>
                  <li className="nav-item">
                    <div  className="dropdown" >
                      <button
                        className={`nav-link dropdown-toggle ${cls.hoverUnderline}`}
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        style={{ paddingRight: "10px", margin: 0, color: "white" }}
                      >
                        फॉर्म
                      </button>
                      <ul className="dropdown-menu">
                        <li>
                          <Link
                            to="/essay"
                            className={`nav-link ${cls.dropdownitem}`}
                            href="#"
                          >
                            शोध निबंध
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/representative"
                            className={`nav-link ${cls.dropdownitem}`}
                            href="#"
                          >
                            प्रतिनिधी नोंदणी फॉर्म
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/bookstall"
                            className={`nav-link ${cls.dropdownitem}`}
                            href="#"
                          >
                            बुक स्टॉल नोंदणी फॉर्म
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </li>
                  <li className="nav-item">
                    <Link
                      to="/feedback"
                      className={`nav-link ${cls.hoverUnderline}`}
                      href="#"
                    >
                      {/* Feedback */}
                      अभिप्राय
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
        </div>
      </section>
    </>
  );
};

export default Header;
