import React from "react";

import Layout from "../../assets/layout.png";
import layoutPDF from "../../downloadable/layout.pdf";
import cls from "./HeroArrangements.module.css";

const Card = ({ title, points }) => (
  <div className={cls.card}>
    <h4>{title}</h4>
    <ul>
      {points.map((point, index) => (
        <li key={index}>{point}</li>
      ))}
    </ul>
  </div>
);

const HeroSection = () => {
  const items = [
    "१) मुख्य प्रवेशद्वार",
    "२) नोंदणी लक्ष",
    "३) कवी भूषण सभागृह",
    "४) इतिहास प्रबोधन संस्था कार्यालय",
    "५) महत्वाच्या व्यक्तीचा कक्ष(vip)",
    "६) पगडी प्रदर्शन",
    "७) आरमार प्रदर्शन",
    "८/९) नाणे प्रदर्शन",
    "१०) शामियाना",
    "११) भोजनकक्ष प्रवेशद्वार (महत्वाच्या व्यक्तीसाठी)",
    "१२) अतिथी भोजन कक्ष",
    "१३) मोकळी जागा",
    "१४) पाकशाळा",
    "१५) भोजन शाळा",
    "१६) स्वच्छता गृह",
    "१७) मोकळी जागा",
    "१८) पुस्तक दालन प्रवेशद्वार",
    "१९ ते ६९) पुस्तक दालन",
    "७०) छ संभाजी महाराज प्रांगण",
    "७१) बैठक व्यवस्था (स्वयंसेवक)",
    "७२) बैठक व्यवस्था (स्वयंसेवक)",
    "७३) बैठक व्यवस्था (प्रतिनिधी)",
    "७४) बैठक व्यवस्था (प्रतिनिधी)",
    "७५) बैठक व्यवस्था (प्रतिनिधी)",
    "७६) बैठक व्यवस्था (आरक्षित)",
    "७७) व्यासपीठ (लोकप्रतीनिधी)",
    "७८) राजसदर (मुख्य व्यासपीठ)",
    "७९) व्यासपीठ (सरदार घराणी)",
    "८०) बैठक व्यवस्था (आरक्षित)",
    "८१) बैठक व्यवस्था (प्रतिनिधी)",
    "८२) बैठक व्यवस्था (प्रतिनिधी)",
    "८३) बैठक व्यवस्था (स्वयंसेवक)",
    "८४) बैठक व्यवस्था (स्वयंसेवक)",
    "८५) सभागृह प्रवेशद्वार",
    "८६) सेल्फी पॉइंट-१",
    "८७) सेल्फी पॉइंट-२",
    "८८) शस्त्र प्रदर्शन",
    "८९) सेल्फी पॉइंट-३",
    "९०) सेल्फी पॉइंट-४",
    "९१) आराम कक्ष",
    "९२) नोंदणी कक्ष",
    "९३) स्वागत कक्ष",
    "९४) अतिथी कक्ष (आगंतुक)",
    "९५) ज. जि. म विद्या प्रसारक संस्था कार्यालय.",
    "९६ ते १००) फूड स्टॉल",
    "१०१) सेल्फी पॉइंट-५",
    "🔼 सरदारांचे फ्लेक्स",
  ];

  const cardData = [
    {
      title: "रेल्वे मार्ग  🚅",
      points: [
        "जळगावकडे येणारे लोहमार्ग",
        "नागपूर मुंबई नागपूर",
        "पुणे नागपूर पुणे ",
        "दिल्ली. मुंबई दिल्ली",
        "सुरत भुसावळ सुरत",
        "अहमदाबाद पुरी अहमदाबाद",
        "कोल्हापूर गोंदिया कोल्हापूर",
        "अहमदाबाद नागपुर अहमदाबाद ",
        "नांदेड अमृतसर नांदेड",
        "(या लोहमार्गावर जळगाव हे मध्यवर्ती रेल्वे स्थानक आहे)",
      ],
    },
    {
      title: "हवाई मार्ग  🛩️",
      points: [
        "विमान मार्ग",
        "औरंगाबाद",
        "अकोला",
        "येथ विमानतळ असून तेथून पुढे मोठया प्रमाणावर बस उपलब्ध आहे",
      ],
    },
    {
      title: "रोड मार्ग 🛤️",
      points: [
        "बस मार्ग",
        "नांदेड जळगाव",
        "सोलापूर जळगाव",
        "बीड जळगाव",
        "संभाजीनगर जळगाव",
        "नासिक जळगाव",
        "नागपूर जळगाव",
        "अकोला जळगाव",
        "अमरावती जळगाव",
      ],
    },
  ];

  return (
    <>
      <section className="container">
        <div className="">
          <div className="row">
            <div className="col-12 col-12">
              <div className={cls.leftSide}>
                <h1 className={cls.smallHeading}>
                  <span className={cls.heading}>
                    {/* Event Layout */}
                    इव्हेंट लेआउट
                    </span>
                </h1>
              </div>
            </div>
            <div className="col-12 col-12">
              <div className={cls.imageContainer}>
                <img
                  src={Layout}
                  className={cls.indiaImage}
                  alt={"Indian Map"}
                  draggable={false}
                />
                <a href={layoutPDF} download="layout.pdf">
                  <button className="btn btn-lg btn-success">
                    {/* Download */}
                    डाउनलोड करा
                    </button>
                </a>
              </div>
            </div>
          </div>

          <div className={cls.contentGrid}>
            {items.map((item, index) => (
              <div key={index} className={cls.contentItem}>
                {item}
              </div>
            ))}
          </div>

          <h2 className={cls.smallHeading}>
            {/* Transportation */}
            ट्रान्सपोर्टेशन माहिती
            </h2>

          <div className={cls.cardsContainer}>
            {cardData.map((card, index) => (
              <Card key={index} title={card.title} points={card.points} />
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default HeroSection;
