import React, { useEffect } from "react";

import Footer from "../components/Footer/Footer";
import Header from "../components/Header/Header";

const BookStall = () => {
  return (
    <>
      <Header />
      <iframe
        src="https://docs.google.com/forms/d/e/1FAIpQLSd8vteBn-F0mv1CehC0xWZ_h9jzLpr64oaVQxu9zUSUCbCKMA/viewform?embedded=true"
        width="100%"
        height="2597"
        frameborder="0"
        marginheight="0"
        marginwidth="0"
      >
        Loading…
      </iframe>
      <Footer />
    </>
  );
};

export default BookStall;
