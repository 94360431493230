import React, { useEffect } from "react";

import Footer from "../components/Footer/Footer";
import Header from "../components/Header/Header";
import cls from "./Essay.module.css";

const Essay = () => {
  return (
    <>
      <Header />
      <div className={cls.container}>
        <h1 className={cls.heading}>नमस्कार!</h1>
        <h2 className={cls.smallHeading}>
          शोध निबंध खालील आंतरराष्ट्रीय जर्नल मध्ये प्रकाशित करण्यात येतील.
          जर्नलची संपूर्ण माहिती खाली नमूद करत आहे.
        </h2>
        <h2 className={cls.journalName}>
          Name of the Journal: International Journal of Arts, Social Sciences
          and Humanities
        </h2>
        <h2 className={cls.journalDetails}>
          (A Quarterly, Refereed, & Peer Reviewed International Journal of Arts,
          Social Sciences and Humanities) <br />
          SRJIF Impact Factor: (2023): 8.7
        </h2>
        <h3 className={cls.publisherDetails}>
          Publisher details: <br /> Association for Advancement of Arts, Social
          Sciences, and Humanities Education and Research (AAASSHER – INDIA)
          1202 Bhoomi Symphony P.N. 16, S-20 Koparkhairane, Navi Mumbai Thane
          400709 (MS) INDIA Website:{" "}
          <a href="https://ijassh.aaassher.org/" className="websiteLink">
            https://ijassh.aaassher.org/
          </a>
        </h3>
        <h2 className={cls.organizer}>आयोजक : -</h2>
        <h3 className={cls.organizerDetails}>
          {" "}
          नूतन मराठा महाविद्यालय जळगाव आणि इतिहास प्रबोधन संस्था महाराष्ट्र
          यांच्या संयुक्त विद्यमाने सार्थ त्रिशती शिवराज्याभिषेक महोत्सवाचे
          औचित्य साधून विश्वातील पहिले{" "}
        </h3>
        <h3 className={cls.eventDate}>
          अखिल भारतीय श्री शिवचरित्र साहित्य संमेलन आयोजित <br /> दिनांक 26 जून
          ते 29 जून 2024 <br /> विषय : "छत्रपती शिवाजी महाराजांच्या शिवचरित्राचा
          वास्तवदर्शी अभ्यास करणे"
        </h3>
        <div className={cls.ulContainer}>
          <h2 className={cls.topic}>संदर्भ विषय-</h2>
          <ul className={cls.referenceTopics}>
            <li>
              १). विदेशी व्यापारी वर्गापासून स्वराज्य रक्षणासाठी शिवरायांचे
              धोरण.{" "}
            </li>
            <li>
              २). शिवरायांनी गडकोट किल्ल्यांच्या जीव भू शस्त्राचा केलेला विचार.
            </li>
            <li>३). भारतीय सैन्य निर्मितीचे जनक छत्रपती शिवराय</li>
            <li>४). गनिमी कावा, त्याच्या क्लृप्ती आणि शिवराय</li>
            <li>५). गनिमी कवा : एक संकल्पना</li>
            <li>६). दक्षिण दिग्विजय : स्वराज्याची उपलब्धी</li>
            <li>७). नव्या राष्ट्राची दिशा : स्वराज्याचे व्यवस्थापन</li>
            <li>८). आरमाराचे जनक : शिवरायांनी आरमाराची स्ट्रेटेजी</li>
            <li>९). स्वराज्याची गुप्तहेर यंत्रणा : एक अभ्यास</li>
            <li>
              १०). शिवकालीन किल्ल्याची ऐतिहासिक आणि भौगोलिक सुबत्ता (दुर्ग
              परीक्षण)
            </li>
            <li>११). छत्रपती शिवरायांचे तत्कालीन सामाजिक विकासाची कामे</li>
          </ul>
        </div>
        <div className={cls.ulContainer}>
          <h2 className={cls.subtopic}>उप विषय-</h2>
          <ul className={cls.subTopics}>
            <li>१). शिवपूर्वकाळ आणि शिव उत्तर काळातील संत साहित्य</li>
            <li>
              २). शिवपूर्व काळातील सामाजिक /आर्थिक /राजकीय/ धार्मिक स्थिती
            </li>
            <li>३). महापुरुषांच्या विचारातून शिवराय</li>
            <li>४). शिव प्रभूंच्या प्रभावळी विषयक विवेचन</li>
            <li>५). समकालीन परकीय सत्ताधीश आणि शिवराय</li>
            <li>६). भारतीय ज्ञान, परंपरा आणि शिवराय</li>
            <li>७). शिवपूर्वकाल आणि शिवकाळातील चलन व्यवस्था</li>
            <li>८). शिवकाळातील गुप्तचर यंत्रणा आणि गनिमी कवा</li>
            <li>९). शिवकाळातील जल व्यवस्थापन तंत्रज्ञान</li>
            <li>१०). शिवपूर्वकाळांनी शिवकाळातील भाषा आणि संस्कृती संवर्धन</li>
            <li>११). शिवरायांना ज्ञात असलेले नकाशा तंत्र</li>
            <li>१२). तत्कालीन वृत्तपत्रांनी घेतलेली शिवरायांची नोंद</li>
          </ul>
        </div>
      </div>
      <div className={cls.container}>
        <h2 className={cls.organizer}>Organized by :</h2>
        <h3 className={cls.organizerDetails}>
          {" "}
          नूतन मराठा महाविद्यालय जळगाव आणि इतिहास प्रबोधन संस्था महाराष्ट्र
          यांच्या संयुक्त विद्यमाने सार्थ त्रिशती शिवराज्याभिषेक महोत्सवाचे
          औचित्य साधून विश्वातील पहिले{" "}
        </h3>
        <h3 className={cls.eventDate}>
          Nutan Maratha Mahavidyalaya, Jalgaon collaboration with Itihas
          Prabodhan Sanstha, Maharashtra. Shri. Shivcharitra Sahitya Sammelan
          <br /> Date 26 to 29 June 2024. <br /> Topic / Theme : "A comprehensive study of
          Chhatrapati Shivaji Maharaj's Shivcharitra"
        </h3>
        <div className={cls.ulContainer}>
          <h2 className={cls.topic}>Reference Issues-</h2>
          <ul className={cls.referenceTopics}>
            <li>
            1). Shivaji Maharaj's policy to protect swarajya from the foreign mercantile class.
            </li>
            <li>
            2). Shivaji Maharaj's considertion of the biology of gadkat fort.
            </li>
            <li>3). Chhatrapati Shivaji father of Indian army formation </li>
            <li>4). Gorilla war in camouflake and shivray </li>
            <li>5). Concept of Guerrilla war</li>
            <li>6). Dakshin Digvijay : Achivement of Swarajya </li>
            <li>7). Direction of new Nation :  Management of Swarajya </li>
            <li>8). Father of Armor : Shivrai and Armara's Strategy </li>
            <li>9). Study of Secret service of Swarajya</li>
            <li>
            10). Historical and Geographical Background (Durga Examin)
            </li>
            <li>11). Chhatrapati shivarai's, then social development works.</li>
          </ul>
        </div>
        <div className={cls.ulContainer}>
          <h2 className={cls.subtopic}>Sub Topic Issues- </h2>
          <ul className={cls.subTopics}>
            <li>1). Sant literature of Pre- Shivaji Maharaj period and post Shivaji Maharaj period</li>
            <li>
            2). Socio- Economic, Political, Religious status of pre-Shivaji Maharaj period            </li>
            <li>3).Shivaji Maharaj through the thought of great Men</li>
            <li>4). Commentary on Shivprabhu's Prabhawali</li>
            <li>5). Contemporary foreign rules and Shivrai</li>
            <li>6). Indian Knowledge, Tradition and Shivrai</li>
            <li>7). Currency system in pre-Shivaji Maharaj period and Shivaji Maharaj period </li>
            <li>8). Guerrillas were hunted down by the intelligence agencies of the Shivaji Maharaj period </li>
            <li>9). Water Management Technology in Shivaji Maharaj period </li>
            <li>10). Pre-Shivaji Maharaj period and Preservation Language and Culture during Shivaji Maharaj period </li>
            <li>11). A Map Technique known to Shivrai</li>
            <li>12).  Shivarai's Record taken by the newspapers of the time.</li>
          </ul>
        </div>
      </div>
      <iframe
        src="https://docs.google.com/forms/d/e/1FAIpQLSeDv83Gu6Bk6Ir7PIR87w83AwBDT9Q2jKtUyp_M4tkDZEyIfQ/viewform?embedded=true"
        width="100%"
        height="2597"
        frameborder="0"
        marginheight="0"
        marginwidth="0"
      >
        Loading…
      </iframe>
      <Footer />
    </>
  );
};

export default Essay;
