import React from "react";

import Amrit from "../../assets/amrit.png";
import cls from "./HeroTimetable.module.css";
import DateButton from "../DateButton/DateButton";

const HeroTimetable = () => {
  return (
    <>
      <section className="">
        <div className="container">
          <div className="row">
            <h1 className={cls.smallHeading}>
              <span className={cls.heading}>
                {/* Timetable */}
                वेळापत्रक
                </span>
            </h1>
            <div className={cls.meetingDetails}>
              <h2 className={cls.meetingDate}>
                संमेलन दिनांक, २६ ते २९ जुन २०२४
              </h2>
              <p className={cls.venue}>
                स्थळ: नूतन मराठा महाविद्यालयाच्या प्रांगणावर
              </p>
            </div>

            <div className={cls.programFormat}>
              <h3 className={cls.formatTitle}>कार्यक्रमाचे स्वरूप</h3>

              <DateButton name="संमेलन दिनांक २६ जुन २०२४ 🔻">
                <div className={cls.dailySchedule}>
                  <p className={cls.time}>दुपारी १२.०० वाजता</p>
                  <p className={cls.event}>प्रदर्षनी उद्घघाटन</p>
                  <ul className={cls.exhibitionList}>
                    <li>१) शस्त्र प्रदर्शन</li>
                    <li>२) नाणी प्रदर्शन</li>
                    <li>३) चित्र प्रदर्शन</li>
                    <li>४) पुस्तक दालन</li>
                    <li>५) आरमार प्रदर्शन</li>
                    <li>६) पगडी प्रदर्शन</li>
                  </ul>
                  <p className={cls.time}>सायंकाळी ५.०० एकत्रीकरण</p>
                  <p className={cls.time}>सायंकाळी ७.०० सांस्कृतिक कार्यक्रम</p>
                </div>
              </DateButton>

              <DateButton name="संमेलन दिनांक २७ जुन २०२४ 🔻">
                <div className={cls.dailySchedule}>
                  <p className={cls.time}>सकाळी ७.०० वाजता</p>
                  <p className={cls.event}>
                    भव्य शोभायात्रा (खान्देश सेंट्रल - शास्त्री टॉवर - जुने बस
                    स्टँड - गोलाणी मार्केट - शिवतीर्थ - संमेलन स्थळ)
                  </p>
                  <p className={cls.time}>९.३० उद्घघाटन:</p>
                  <ul className={cls.sessionList}>
                    <li>ऊर्जा मंत्र</li>
                    <li>१) प्रास्ताविक</li>
                    <li>२) निमंत्रक संबोधन</li>
                    <li>३) स्वागताध्यक्ष संबोधन</li>
                    <li>४) संरक्षक संबोधन</li>
                    <li>५) प्रमुख अतिथी</li>
                    <li>६) स्वागत आणि सत्कार सरदार घराणे</li>
                    <li>७) मावळ्यांच्या सत्कार</li>
                    <li>८) जीवन गौरव पुरस्कार</li>
                    <li>९) संमेलाध्यक्ष संबोधन</li>
                    <li>१०) आभार</li>
                  </ul>
                  <p className={cls.sessionTime}>
                    सत्र क्रमांक १ दुपारी २.०० ते २.४५
                  </p>
                  <p className={cls.event}>बीज भाषण</p>
                  <p className={cls.sessionTime}>
                    सत्र क्रमांक २) दुपारी ३.१५ ते ५.००
                  </p>
                  <p className={cls.event}>परिसंवाद</p>
                  <p className={cls.sessionTime}>
                    सत्र क्रमांक ३) ५.१५ ते ६.३०
                  </p>
                  <p className={cls.event}>परिसंवाद</p>
                  <p className={cls.sessionTime}>
                    सत्र क्रमांक ४) ७.०० ते १०.००
                  </p>
                  <p className={cls.event}>शाहिरी पोवाडे.</p>
                </div>
              </DateButton>

              <DateButton name="संमेलन दिनांक २८ जुन २०२४ 🔻">
                <div className={cls.dailySchedule}>
                  <p className={cls.sessionTime}>
                    सत्र क्रमांक ५ सकाळी ९.०० ते १०.३०
                  </p>
                  <p className={cls.event}>चर्चा सत्र /परिसंवाद</p>
                  <p className={cls.sessionTime}>
                    सत्र क्रमांक ६ ११.०० ते १२.३०
                  </p>
                  <p className={cls.event}>परिसंवाद</p>
                  <p className={cls.sessionTime}>
                    सत्र क्रमांक ७ दुपारी ४.०० ते ५.३०
                  </p>
                  <p className={cls.event}>परिसंवाद</p>
                  <p className={cls.sessionTime}>सत्र क्रमांक ८ ५.४५ ते ६.३०</p>
                  <p className={cls.event}>व्याख्यान</p>
                  <p className={cls.sessionTime}>सत्र क्रमांक ९</p>
                  <p className={cls.event}>मुलाखत</p>
                  <p className={cls.sessionTime}>सत्र क्रमांक १०</p>
                  <p className={cls.event}>शाहिरी गोंधळ.</p>
                </div>
              </DateButton>

              <DateButton name="संमेलन दिनांक २९ जुन २०२४ 🔻">
                <div className={cls.dailySchedule}>
                  {/* <h4 className={cls.dateTitle}>संमेलन दिनांक २९ जुन २०२४</h4> */}
                  <p className={cls.sessionTime}>
                    सत्र क्रमांक ११ सकाळी ९.०० ते १०.३०
                  </p>
                  <p className={cls.event}>परिसंवाद</p>
                  <p className={cls.sessionTime}>
                    सत्र क्रमांक १२ ११.०० ते १२.३० खुल
                  </p>
                  <p className={cls.event}>ठराव</p>
                  <ul className={cls.sessionList}>
                    <li>
                      १) छत्रपतींचा इतिहास शालेय अभ्यासक्रमात अंतर्भूत करावा{" "}
                    </li>
                    <li>
                      २) विद्यापीठ स्तरावर छत्रपती शिवरायांच्या चरित्रावर पदवी
                      अथवा पदविका कोर्स सुरू करावा{" "}
                    </li>
                    <li>
                      ३) छत्रपती शिवरायांचा वास्तववादी इतिहासावर अभ्यास व्हावा{" "}
                    </li>
                    <li>
                      ४) शिवकालीन साहित्याच्या अभ्यासासाठी उपयुक्त अश्या
                      फारसी,अरबी,उर्दू,अर्धमागधी, पाली आणि मोडी भाषा
                      सवार्धनासाठी शासन स्तरावर प्रयत्न करावे
                    </li>
                    <li>५) </li>
                    <li>६) पुढील संमेलन स्थान प्रस्ताव </li>
                  </ul>
                  <p className={cls.sessionTime}>सत्र क्रमांक १३ दुपारी २.१५</p>
                  <p className={cls.event}>समारोप</p>
                  <p className={cls.event}>प्रास्ताविक</p>
                  <p className={cls.event}>निमंत्रक</p>
                  <p className={cls.event}>स्वागताध्यक्ष</p>
                  <p className={cls.event}>प्रमुख अतिथी</p>
                  <p className={cls.event}>संमेलनअध्यक्ष</p>
                  <p className={cls.event}>समारोप</p>
                  <p className={cls.event}>आभार</p>
                  <p className={cls.event}>ध्वजावतरण</p>
                  <p className={cls.event}>ऊर्जा मंत्र</p>
                  <p className={cls.event}>आभार</p>
                </div>
              </DateButton>
              {/* Day 1 */}
              {/* <div className={cls.dailySchedule}>
                <h4 className={cls.dateTitle}>संमेलन दिनांक २६ जुन २०२४</h4>
                <p className={cls.time}>दुपारी १२.०० वाजता</p>
                <p className={cls.event}>प्रदर्षनी उद्घघाटन</p>
                <ul className={cls.exhibitionList}>
                  <li>१) शस्त्र प्रदर्शन</li>
                  <li>२) नाणी प्रदर्शन</li>
                  <li>३) चित्र प्रदर्शन</li>
                  <li>४) पुस्तक दालन</li>
                  <li>५) आरमार प्रदर्शन</li>
                  <li>६) पगडी प्रदर्शन</li>
                </ul>
                <p className={cls.time}>सायंकाळी ५.०० एकत्रीकरण</p>
                <p className={cls.time}>सायंकाळी ७.०० सांस्कृतिक कार्यक्रम</p>
              </div> */}

              {/* Day 2 */}
              {/* <div className={cls.dailySchedule}>
                <h4 className={cls.dateTitle}>संमेलन दिनांक २७ जुन २०२४</h4>
                <p className={cls.time}>सकाळी ७.०० वाजता</p>
                <p className={cls.event}>
                  भव्य शोभायात्रा (खान्देश सेंट्रल - शास्त्री टॉवर - जुने बस
                  स्टँड - गोलाणी मार्केट - शिवतीर्थ - संमेलन स्थळ)
                </p>
                <p className={cls.time}>९.३० उद्घघाटन:</p>
                <ul className={cls.sessionList}>
                  <li>ऊर्जा मंत्र</li>
                  <li>१) प्रास्ताविक</li>
                  <li>२) निमंत्रक संबोधन</li>
                  <li>३) स्वागताध्यक्ष संबोधन</li>
                  <li>४) संरक्षक संबोधन</li>
                  <li>५) प्रमुख अतिथी</li>
                  <li>६) स्वागत आणि सत्कार सरदार घराणे</li>
                  <li>७) मावळ्यांच्या सत्कार</li>
                  <li>८) जीवन गौरव पुरस्कार</li>
                  <li>९) संमेलाध्यक्ष संबोधन</li>
                  <li>१०) आभार</li>
                </ul>
                <p className={cls.sessionTime}>
                  सत्र क्रमांक १ दुपारी २.०० ते २.४५
                </p>
                <p className={cls.event}>बीज भाषण</p>
                <p className={cls.sessionTime}>
                  सत्र क्रमांक २) दुपारी ३.१५ ते ५.००
                </p>
                <p className={cls.event}>परिसंवाद</p>
                <p className={cls.sessionTime}>सत्र क्रमांक ३) ५.१५ ते ६.३०</p>
                <p className={cls.event}>परिसंवाद</p>
                <p className={cls.sessionTime}>सत्र क्रमांक ४) ७.०० ते १०.००</p>
                <p className={cls.event}>शाहिरी पोवाडे.</p>
              </div> */}

              {/* Day 3 */}
              {/* <div className={cls.dailySchedule}>
                <h4 className={cls.dateTitle}>संमेलन दिनांक २८ जुन २०२४</h4>
                <p className={cls.sessionTime}>
                  सत्र क्रमांक ५ सकाळी ९.०० ते १०.३०
                </p>
                <p className={cls.event}>चर्चा सत्र /परिसंवाद</p>
                <p className={cls.sessionTime}>सत्र क्रमांक ६ ११.०० ते १२.३०</p>
                <p className={cls.event}>परिसंवाद</p>
                <p className={cls.sessionTime}>
                  सत्र क्रमांक ७ दुपारी ४.०० ते ५.३०
                </p>
                <p className={cls.event}>परिसंवाद</p>
                <p className={cls.sessionTime}>सत्र क्रमांक ८ ५.४५ ते ६.३०</p>
                <p className={cls.event}>व्याख्यान</p>
                <p className={cls.sessionTime}>सत्र क्रमांक ९</p>
                <p className={cls.event}>मुलाखत</p>
                <p className={cls.sessionTime}>सत्र क्रमांक १०</p>
                <p className={cls.event}>शाहिरी गोंधळ.</p>
              </div> */}

              {/* Day 4 */}
              {/* <div className={cls.dailySchedule}>
                <h4 className={cls.dateTitle}>संमेलन दिनांक २९ जुन २०२४</h4>
                <p className={cls.sessionTime}>
                  सत्र क्रमांक ११ सकाळी ९.०० ते १०.३०
                </p>
                <p className={cls.event}>परिसंवाद</p>
                <p className={cls.sessionTime}>
                  सत्र क्रमांक १२ ११.०० ते १२.३० खुल
                </p>
                <p className={cls.event}>ठराव</p>
                <ul className={cls.sessionList}>
                  <li>
                    १) छत्रपतींचा इतिहास शालेय अभ्यासक्रमात अंतर्भूत करावा{" "}
                  </li>
                  <li>
                    २) विद्यापीठ स्तरावर छत्रपती शिवरायांच्या चरित्रावर पदवी
                    अथवा पदविका कोर्स सुरू करावा{" "}
                  </li>
                  <li>
                    ३) छत्रपती शिवरायांचा वास्तववादी इतिहासावर अभ्यास व्हावा{" "}
                  </li>
                  <li>
                    ४) शिवकालीन साहित्याच्या अभ्यासासाठी उपयुक्त अश्या
                    फारसी,अरबी,उर्दू,अर्धमागधी, पाली आणि मोडी भाषा सवार्धनासाठी
                    शासन स्तरावर प्रयत्न करावे
                  </li>
                  <li>५) </li>
                  <li>६) पुढील संमेलन स्थान प्रस्ताव </li>
                </ul>
                <p className={cls.sessionTime}>सत्र क्रमांक १३ दुपारी २.१५</p>
                <p className={cls.event}>समारोप</p>
                <p className={cls.event}>प्रास्ताविक</p>
                <p className={cls.event}>निमंत्रक</p>
                <p className={cls.event}>स्वागताध्यक्ष</p>
                <p className={cls.event}>प्रमुख अतिथी</p>
                <p className={cls.event}>संमेलनअध्यक्ष</p>
                <p className={cls.event}>समारोप</p>
                <p className={cls.event}>आभार</p>
                <p className={cls.event}>ध्वजावतरण</p>
                <p className={cls.event}>ऊर्जा मंत्र</p>
                <p className={cls.event}>आभार</p>
              </div> */}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default HeroTimetable;
