import React, { useState, useEffect } from "react";

import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
} from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

import styles from "./Sponsors.module.css";

import logo1 from "../../assets/logo1.jpg";
import logo2 from "../../assets/logo2.jpg";
import logo3 from "../../assets/logo3.png";
import logo4 from "../../assets/logo4.jpg";
import logo5 from "../../assets/logo5.jpg";
import logo6 from "../../assets/logo6.jpeg";

const logos = [
  { id: 1, logo: logo1, alt: "Logo 1" },
  { id: 2, logo: logo2, alt: "Logo 2" },
  { id: 3, logo: logo3, alt: "Logo 3" },
  { id: 4, logo: logo4, alt: "Logo 4" },
  // { id: 5, logo: logo5, alt: "Logo 5" },
  { id: 6, logo: logo6, alt: "Logo 6" },
];

const Sponsors = () => {
  // TODO - Remove this part if un-necessary
  // Use `useWindowSize` hook present in hooks folder
  // in src

  // const [width, setWidth] = useState(window.innerWidth);

  // useEffect(() => {
  //   const handleResize = () => {
  //     setWidth(window.innerWidth);
  //   };

  //   window.addEventListener("resize", handleResize);

  //   return () => {
  //     window.removeEventListener("resize", handleResize);
  //   };
  // }, []);
  return (
    <section className="container">
      <h1 className={styles.smallHeading}>
        <span className={styles.heading}>
          {/* Sponsors */}
          प्रायोजक
          </span>
      </h1>
      <div className={styles.swiperContainer}>
        <Swiper
          spaceBetween={30}
          slidesPerView={1}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          pagination={{
            clickable: true,
          }}
          navigation={true}
          modules={[Autoplay, Pagination, Navigation]}
          loop={true}
          className={styles.mySwiper}
        >
          {logos.map((logo) => (
            <SwiperSlide key={logo.id} className={styles.swiperSlide}>
              <div className={styles.swiperImageContainer}>
                <img
                  src={logo.logo}
                  alt={logo.alt}
                  className={styles.swiperImage}
                />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </section>
  );
};

export default Sponsors;
