import React from "react";
import { Link } from "react-router-dom";

import Footer from "../components/Footer/Footer";
import Header from "../components/Header/Header";

const SuggestionsForResolution = () => {
  return (
    <>
      <Header />
      <div className="container">
        <div style={{ margin: "4rem 0" }} className={".table-responsive"}>
          <table
            style={{ padding: "3rem", borderColor: "#6e312e" }}
            className="table-striped table"
          >
            <thead>
              <tr>
                <th style={{ padding: "1.25rem 1rem" }} scope="col">
                  No.
                </th>
                <th style={{ padding: "1.25rem 0" }} scope="col">
                  Resolution
                </th>
                <th style={{ padding: "1.25rem 0" }} scope="col">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th style={{ padding: "1.25rem 1rem" }} scope="row">
                  1
                </th>
                <td style={{ padding: "1.25rem 0" }}>Resolution - 1</td>
                <td style={{ padding: "1.25rem 0" }}>
                  <div
                    style={{
                      // width: "50%",
                      display: "flex",
                      gap: "16px",
                    }}
                  >
                    <a
                      href={
                        "https://drive.google.com/file/d/13p3BwcnF-QfzQ8EHowCU2DsEemFs7tbu/view?usp=sharing"
                      }
                      target="_blank"
                      rel="noreferrer"
                    >
                      <button
                        className="btn btn-primary"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title="Download"
                      >
                        {/*<svg*/}
                        {/*  xmlns="http://www.w3.org/2000/svg"*/}
                        {/*  width="24"*/}
                        {/*  height="24"*/}
                        {/*  viewBox="0 0 24 24"*/}
                        {/*  fill="none"*/}
                        {/*>*/}
                        {/*  <path*/}
                        {/*    d="M12 16L7 11L8.4 9.55L11 12.15V4H13V12.15L15.6 9.55L17 11L12 16ZM6 20C5.45 20 4.97933 19.8043 4.588 19.413C4.19667 19.0217 4.00067 18.5507 4 18V15H6V18H18V15H20V18C20 18.55 19.8043 19.021 19.413 19.413C19.0217 19.805 18.5507 20.0007 18 20H6Z"*/}
                        {/*    fill="white"*/}
                        {/*  />*/}
                        {/*</svg>*/}
                        View
                      </button>
                    </a>

                    <Link
                      to="/suggestions"
                      // className={`nav-link ${cls.hoverUnderline}`}
                      href="#"
                    >
                      <button
                        className="btn btn-success"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title="Suggestions for Resolution"
                      >
                        {/*<svg*/}
                        {/*  xmlns="http://www.w3.org/2000/svg"*/}
                        {/*  width="24"*/}
                        {/*  height="24"*/}
                        {/*  viewBox="0 0 24 24"*/}
                        {/*  fill="none"*/}
                        {/*>*/}
                        {/*  <path*/}
                        {/*    d="M6 18L3.7 20.3C3.38334 20.6167 3.02067 20.6877 2.612 20.513C2.20333 20.3383 1.99933 20.0257 2 19.575V4C2 3.45 2.196 2.97933 2.588 2.588C2.98 2.19667 3.45067 2.00067 4 2H20C20.55 2 21.021 2.196 21.413 2.588C21.805 2.98 22.0007 3.45067 22 4V16C22 16.55 21.8043 17.021 21.413 17.413C21.0217 17.805 20.5507 18.0007 20 18H6ZM12 15C12.2833 15 12.521 14.904 12.713 14.712C12.905 14.52 13.0007 14.2827 13 14C13 13.7167 12.904 13.4793 12.712 13.288C12.52 13.0967 12.2827 13.0007 12 13C11.7167 13 11.4793 13.096 11.288 13.288C11.0967 13.48 11.0007 13.7173 11 14C11 14.2833 11.096 14.521 11.288 14.713C11.48 14.905 11.7173 15.0007 12 15ZM12 11C12.2833 11 12.521 10.904 12.713 10.712C12.905 10.52 13.0007 10.2827 13 10V6C13 5.71667 12.904 5.47933 12.712 5.288C12.52 5.09667 12.2827 5.00067 12 5C11.7167 5 11.4793 5.096 11.288 5.288C11.0967 5.48 11.0007 5.71733 11 6V10C11 10.2833 11.096 10.521 11.288 10.713C11.48 10.905 11.7173 11.0007 12 11Z"*/}
                        {/*    fill="white"*/}
                        {/*  />*/}
                        {/*</svg>*/}
                        Suggestions
                      </button>
                    </Link>
                  </div>
                </td>
              </tr>
              <tr>
                <th style={{ padding: "1.25rem 1rem" }} scope="row">
                  2
                </th>
                <td style={{ padding: "1.25rem 0" }}>Resolution - 2</td>
                <td style={{ padding: "1.25rem 0" }}>
                  <div
                    style={{
                      // width: "50%",
                      display: "flex",
                      gap: "16px",
                    }}
                  >
                    <a
                      href={
                        "https://drive.google.com/file/d/13OF7mZpthTPcIIFHsxZEvauK2pKoDBc4/view?usp=sharing"
                      }
                      target="_blank"
                      rel="noreferrer"
                    >
                      <button
                        className="btn btn-primary"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title="Download"
                      >
                        {/*<svg*/}
                        {/*  xmlns="http://www.w3.org/2000/svg"*/}
                        {/*  width="24"*/}
                        {/*  height="24"*/}
                        {/*  viewBox="0 0 24 24"*/}
                        {/*  fill="none"*/}
                        {/*>*/}
                        {/*  <path*/}
                        {/*    d="M12 16L7 11L8.4 9.55L11 12.15V4H13V12.15L15.6 9.55L17 11L12 16ZM6 20C5.45 20 4.97933 19.8043 4.588 19.413C4.19667 19.0217 4.00067 18.5507 4 18V15H6V18H18V15H20V18C20 18.55 19.8043 19.021 19.413 19.413C19.0217 19.805 18.5507 20.0007 18 20H6Z"*/}
                        {/*    fill="white"*/}
                        {/*  />*/}
                        {/*</svg>*/}
                        View
                      </button>
                    </a>
                    <Link
                      to="/suggestions"
                      // className={`nav-link ${cls.hoverUnderline}`}
                      href="#"
                    >
                      <button
                        className="btn btn-success"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title="Suggestions for Resolution"
                      >
                        {/*<svg*/}
                        {/*  xmlns="http://www.w3.org/2000/svg"*/}
                        {/*  width="24"*/}
                        {/*  height="24"*/}
                        {/*  viewBox="0 0 24 24"*/}
                        {/*  fill="none"*/}
                        {/*>*/}
                        {/*  <path*/}
                        {/*    d="M6 18L3.7 20.3C3.38334 20.6167 3.02067 20.6877 2.612 20.513C2.20333 20.3383 1.99933 20.0257 2 19.575V4C2 3.45 2.196 2.97933 2.588 2.588C2.98 2.19667 3.45067 2.00067 4 2H20C20.55 2 21.021 2.196 21.413 2.588C21.805 2.98 22.0007 3.45067 22 4V16C22 16.55 21.8043 17.021 21.413 17.413C21.0217 17.805 20.5507 18.0007 20 18H6ZM12 15C12.2833 15 12.521 14.904 12.713 14.712C12.905 14.52 13.0007 14.2827 13 14C13 13.7167 12.904 13.4793 12.712 13.288C12.52 13.0967 12.2827 13.0007 12 13C11.7167 13 11.4793 13.096 11.288 13.288C11.0967 13.48 11.0007 13.7173 11 14C11 14.2833 11.096 14.521 11.288 14.713C11.48 14.905 11.7173 15.0007 12 15ZM12 11C12.2833 11 12.521 10.904 12.713 10.712C12.905 10.52 13.0007 10.2827 13 10V6C13 5.71667 12.904 5.47933 12.712 5.288C12.52 5.09667 12.2827 5.00067 12 5C11.7167 5 11.4793 5.096 11.288 5.288C11.0967 5.48 11.0007 5.71733 11 6V10C11 10.2833 11.096 10.521 11.288 10.713C11.48 10.905 11.7173 11.0007 12 11Z"*/}
                        {/*    fill="white"*/}
                        {/*  />*/}
                        {/*</svg>*/}
                        Suggestions
                      </button>
                    </Link>
                  </div>
                </td>
              </tr>

              <tr>
                <th style={{ padding: "1.25rem 1rem" }} scope="row">
                  3
                </th>
                <td style={{ padding: "1.25rem 0" }}>Resolution - 3</td>
                <td style={{ padding: "1.25rem 0" }}>
                  <div
                    style={{
                      // width: "50%",
                      display: "flex",
                      gap: "16px",
                    }}
                  >
                    <a
                      href={
                        "https://drive.google.com/file/d/13_4N5hix0gMM8oSX04c9r1RCfIKWcVym/view?usp=sharing"
                      }
                      target="_blank"
                      rel="noreferrer"
                    >
                      <button
                        className="btn btn-primary"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title="Download"
                      >
                        {/*<svg*/}
                        {/*  xmlns="http://www.w3.org/2000/svg"*/}
                        {/*  width="24"*/}
                        {/*  height="24"*/}
                        {/*  viewBox="0 0 24 24"*/}
                        {/*  fill="none"*/}
                        {/*>*/}
                        {/*  <path*/}
                        {/*    d="M12 16L7 11L8.4 9.55L11 12.15V4H13V12.15L15.6 9.55L17 11L12 16ZM6 20C5.45 20 4.97933 19.8043 4.588 19.413C4.19667 19.0217 4.00067 18.5507 4 18V15H6V18H18V15H20V18C20 18.55 19.8043 19.021 19.413 19.413C19.0217 19.805 18.5507 20.0007 18 20H6Z"*/}
                        {/*    fill="white"*/}
                        {/*  />*/}
                        {/*</svg>*/}
                        View
                      </button>
                    </a>
                    <Link
                      to="/suggestions"
                      // className={`nav-link ${cls.hoverUnderline}`}
                      href="#"
                    >
                      <button
                        className="btn btn-success"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title="Suggestions for Resolution"
                      >
                        {/*<svg*/}
                        {/*  xmlns="http://www.w3.org/2000/svg"*/}
                        {/*  width="24"*/}
                        {/*  height="24"*/}
                        {/*  viewBox="0 0 24 24"*/}
                        {/*  fill="none"*/}
                        {/*>*/}
                        {/*  <path*/}
                        {/*    d="M6 18L3.7 20.3C3.38334 20.6167 3.02067 20.6877 2.612 20.513C2.20333 20.3383 1.99933 20.0257 2 19.575V4C2 3.45 2.196 2.97933 2.588 2.588C2.98 2.19667 3.45067 2.00067 4 2H20C20.55 2 21.021 2.196 21.413 2.588C21.805 2.98 22.0007 3.45067 22 4V16C22 16.55 21.8043 17.021 21.413 17.413C21.0217 17.805 20.5507 18.0007 20 18H6ZM12 15C12.2833 15 12.521 14.904 12.713 14.712C12.905 14.52 13.0007 14.2827 13 14C13 13.7167 12.904 13.4793 12.712 13.288C12.52 13.0967 12.2827 13.0007 12 13C11.7167 13 11.4793 13.096 11.288 13.288C11.0967 13.48 11.0007 13.7173 11 14C11 14.2833 11.096 14.521 11.288 14.713C11.48 14.905 11.7173 15.0007 12 15ZM12 11C12.2833 11 12.521 10.904 12.713 10.712C12.905 10.52 13.0007 10.2827 13 10V6C13 5.71667 12.904 5.47933 12.712 5.288C12.52 5.09667 12.2827 5.00067 12 5C11.7167 5 11.4793 5.096 11.288 5.288C11.0967 5.48 11.0007 5.71733 11 6V10C11 10.2833 11.096 10.521 11.288 10.713C11.48 10.905 11.7173 11.0007 12 11Z"*/}
                        {/*    fill="white"*/}
                        {/*  />*/}
                        {/*</svg>*/}
                        Suggestions
                      </button>
                    </Link>
                  </div>
                </td>
              </tr>

              <tr>
                <th style={{ padding: "1.25rem 1rem" }} scope="row">
                  4
                </th>
                <td style={{ padding: "1.25rem 0" }}>Resolution - 4</td>
                <td style={{ padding: "1.25rem 0" }}>
                  <div
                    style={{
                      // width: "50%",
                      display: "flex",
                      gap: "16px",
                    }}
                  >
                    <a
                      href={
                        "https://drive.google.com/file/d/13TpmMex7ad3Dc3UkUl0f_u5QeZjgAXMc/view?usp=sharing"
                      }
                      target="_blank"
                      rel="noreferrer"
                    >
                      <button
                        className="btn btn-primary"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title="Download"
                      >
                        {/*<svg*/}
                        {/*  xmlns="http://www.w3.org/2000/svg"*/}
                        {/*  width="24"*/}
                        {/*  height="24"*/}
                        {/*  viewBox="0 0 24 24"*/}
                        {/*  fill="none"*/}
                        {/*>*/}
                        {/*  <path*/}
                        {/*    d="M12 16L7 11L8.4 9.55L11 12.15V4H13V12.15L15.6 9.55L17 11L12 16ZM6 20C5.45 20 4.97933 19.8043 4.588 19.413C4.19667 19.0217 4.00067 18.5507 4 18V15H6V18H18V15H20V18C20 18.55 19.8043 19.021 19.413 19.413C19.0217 19.805 18.5507 20.0007 18 20H6Z"*/}
                        {/*    fill="white"*/}
                        {/*  />*/}
                        {/*</svg>*/}
                        View
                      </button>
                    </a>
                    <Link
                      to="/suggestions"
                      // className={`nav-link ${cls.hoverUnderline}`}
                      href="#"
                    >
                      <button
                        className="btn btn-success"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title="Suggestions for Resolution"
                      >
                        {/*<svg*/}
                        {/*  xmlns="http://www.w3.org/2000/svg"*/}
                        {/*  width="24"*/}
                        {/*  height="24"*/}
                        {/*  viewBox="0 0 24 24"*/}
                        {/*  fill="none"*/}
                        {/*>*/}
                        {/*  <path*/}
                        {/*    d="M6 18L3.7 20.3C3.38334 20.6167 3.02067 20.6877 2.612 20.513C2.20333 20.3383 1.99933 20.0257 2 19.575V4C2 3.45 2.196 2.97933 2.588 2.588C2.98 2.19667 3.45067 2.00067 4 2H20C20.55 2 21.021 2.196 21.413 2.588C21.805 2.98 22.0007 3.45067 22 4V16C22 16.55 21.8043 17.021 21.413 17.413C21.0217 17.805 20.5507 18.0007 20 18H6ZM12 15C12.2833 15 12.521 14.904 12.713 14.712C12.905 14.52 13.0007 14.2827 13 14C13 13.7167 12.904 13.4793 12.712 13.288C12.52 13.0967 12.2827 13.0007 12 13C11.7167 13 11.4793 13.096 11.288 13.288C11.0967 13.48 11.0007 13.7173 11 14C11 14.2833 11.096 14.521 11.288 14.713C11.48 14.905 11.7173 15.0007 12 15ZM12 11C12.2833 11 12.521 10.904 12.713 10.712C12.905 10.52 13.0007 10.2827 13 10V6C13 5.71667 12.904 5.47933 12.712 5.288C12.52 5.09667 12.2827 5.00067 12 5C11.7167 5 11.4793 5.096 11.288 5.288C11.0967 5.48 11.0007 5.71733 11 6V10C11 10.2833 11.096 10.521 11.288 10.713C11.48 10.905 11.7173 11.0007 12 11Z"*/}
                        {/*    fill="white"*/}
                        {/*  />*/}
                        {/*</svg>*/}
                        Suggestions
                      </button>
                    </Link>
                  </div>
                </td>
              </tr>

              <tr>
                <th style={{ padding: "1.25rem 1rem" }} scope="row">
                  5
                </th>
                <td style={{ padding: "1.25rem 0" }}>Resolution - 5</td>
                <td style={{ padding: "1.25rem 0" }}>
                  <div
                    style={{
                      // width: "50%",
                      display: "flex",
                      gap: "16px",
                    }}
                  >
                    <button
                      className="btn btn-primary"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title="Download"
                    >
                      {/*<svg*/}
                      {/*  xmlns="http://www.w3.org/2000/svg"*/}
                      {/*  width="24"*/}
                      {/*  height="24"*/}
                      {/*  viewBox="0 0 24 24"*/}
                      {/*  fill="none"*/}
                      {/*>*/}
                      {/*  <path*/}
                      {/*    d="M12 16L7 11L8.4 9.55L11 12.15V4H13V12.15L15.6 9.55L17 11L12 16ZM6 20C5.45 20 4.97933 19.8043 4.588 19.413C4.19667 19.0217 4.00067 18.5507 4 18V15H6V18H18V15H20V18C20 18.55 19.8043 19.021 19.413 19.413C19.0217 19.805 18.5507 20.0007 18 20H6Z"*/}
                      {/*    fill="white"*/}
                      {/*  />*/}
                      {/*</svg>*/}
                      View
                    </button>
                    <Link
                      to="/suggestions"
                      // className={`nav-link ${cls.hoverUnderline}`}
                      href="#"
                    >
                      <button
                        className="btn btn-success"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title="Suggestions for Resolution"
                      >
                        {/*<svg*/}
                        {/*  xmlns="http://www.w3.org/2000/svg"*/}
                        {/*  width="24"*/}
                        {/*  height="24"*/}
                        {/*  viewBox="0 0 24 24"*/}
                        {/*  fill="none"*/}
                        {/*>*/}
                        {/*  <path*/}
                        {/*    d="M6 18L3.7 20.3C3.38334 20.6167 3.02067 20.6877 2.612 20.513C2.20333 20.3383 1.99933 20.0257 2 19.575V4C2 3.45 2.196 2.97933 2.588 2.588C2.98 2.19667 3.45067 2.00067 4 2H20C20.55 2 21.021 2.196 21.413 2.588C21.805 2.98 22.0007 3.45067 22 4V16C22 16.55 21.8043 17.021 21.413 17.413C21.0217 17.805 20.5507 18.0007 20 18H6ZM12 15C12.2833 15 12.521 14.904 12.713 14.712C12.905 14.52 13.0007 14.2827 13 14C13 13.7167 12.904 13.4793 12.712 13.288C12.52 13.0967 12.2827 13.0007 12 13C11.7167 13 11.4793 13.096 11.288 13.288C11.0967 13.48 11.0007 13.7173 11 14C11 14.2833 11.096 14.521 11.288 14.713C11.48 14.905 11.7173 15.0007 12 15ZM12 11C12.2833 11 12.521 10.904 12.713 10.712C12.905 10.52 13.0007 10.2827 13 10V6C13 5.71667 12.904 5.47933 12.712 5.288C12.52 5.09667 12.2827 5.00067 12 5C11.7167 5 11.4793 5.096 11.288 5.288C11.0967 5.48 11.0007 5.71733 11 6V10C11 10.2833 11.096 10.521 11.288 10.713C11.48 10.905 11.7173 11.0007 12 11Z"*/}
                        {/*    fill="white"*/}
                        {/*  />*/}
                        {/*</svg>*/}
                        Suggestions
                      </button>
                    </Link>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default SuggestionsForResolution;
