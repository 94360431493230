import React from "react";

import facebook from "../assets/fb.png";
import instagram from "../assets/insta.png";
import telegram from "../assets/tel.png";
import whatsapp from "../assets/wa.png";
import x from "../assets/x.png";
import youtube from "../assets/yt.png";
import Footer from "../components/Footer/Footer";
import Header from "../components/Header/Header";
import cls from "./Socials.module.css";

function Social() {
  return (
    <>
      <Header />
      <div className={"container"}>
        <h1 className={cls.smallHeading}>
          <span className={cls.heading}>
            {/* Social Media */}
            सोशल मीडिया
            </span>
        </h1>
        <div className={cls.row}>
          <div className={cls.contactBox}>
            <div className={cls.socialIcon}>
              <a
                href="https://www.facebook.com/profile.php?id=61555682295936&mibextid=ZbWKwL"
                target="_blank"
                rel="noreferrer"
              >
                <img src={facebook} alt="Facebook Icon" />
              </a>
            </div>
            <h3 className={cls.contactTitle}>Facebook</h3>
          </div>

          <div className={cls.contactBox}>
            <div className={cls.socialIcon}>
              <a
                href="https://www.facebook.com/ABVPVOICE"
                target="_blank"
                rel="noreferrer"
              >
                <img src={instagram} alt="Instagram Icon" />
              </a>
            </div>
            <h3 className={cls.contactTitle}>Instagram</h3>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Social;
