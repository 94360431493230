import React, { useEffect } from "react";

import Footer from "../components/Footer/Footer";
import Header from "../components/Header/Header";

const RepresentativeForm = () => {
  return (
    <>
      <Header />
      <iframe
        src="https://docs.google.com/forms/d/e/1FAIpQLSdoooeByI2hASORhBahRlMm7bnWckcsXXKtfSN5VjQbG3tYLQ/viewform?embedded=true"
        width="100%"
        height="2597"
        frameborder="0"
        marginheight="0"
        marginwidth="0"
      >
        Loading…
      </iframe>
      <Footer />
    </>
  );
};

export default RepresentativeForm;
