import Footer from "../components/Footer/Footer";
import Header from "../components/Header/Header";
import SignIn from "../components/SignIn/SignIn";

const Login = () => {
  return (
    <>
      <Header />
      <SignIn />
      <Footer />
    </>
  );
};

export default Login;
