import React from "react";

import leftLogo from "../../assets/prabhodhan.png";
import rightLogo from "../../assets/homepgimg.jpg";
import aboutPDF from "../../downloadable/about-abvp.pdf";
import cls from "./HeroSection.module.css";
import middleLogo from "../../assets/logo.jpg";
import DateButton from "../DateButton/DateButton";
import landingbg from "../../assets/landingbg.jpeg";
import landingbg2 from "../../assets/landingbg2.jpeg";

const HeroSection = () => {
  return (
    <>
      <section className="">
        <div className="container">
          <div className="row">
            <div className={`col-12`}>
              {/* <div className={cls.imageContainer}>
                <div className={cls.imageCard}>
                  <img
                    src={leftLogo}
                    alt="Left Logo"
                    className={cls.responsiveImage}
                  />
                </div>
                <div className={cls.imageCard}>
                  <img
                    src={middleLogo}
                    alt="Middle Logo"
                    className={cls.responsiveImage}
                  />
                </div>
                <div className={cls.imageCard}>
                  <img
                    src={rightLogo}
                    alt="Right Logo"
                    className={cls.responsiveImage}
                  />
                </div>
              </div> */}
            </div>
          </div>
          <div className={cls.shivalayout}>
            <div className={cls.yearHeadingContainer}>
              <img
                src={landingbg}
                alt={"Year"}
                className={cls.yearImage}
                draggable={false}
              />
            </div>
            <div className={cls.yearHeadingContainer}>
              <img
                src={landingbg2}
                alt={"Year"}
                className={cls.yearImage}
                draggable={false}
              />
            </div>
            
            {/* <div>
              <div className={cls.announcement}>
                <div className={cls.banner}>
                  <h1 className={cls.organizername}>
                    नूतन मराठा महाविद्यालय, जळगांव
                  </h1>
                  <h1 className={cls.organizername}>
                    इतिहास प्रबोधन संस्था महाराष्ट्र
                  </h1>
                  <h2 className={cls.eventtitle}>
                    यांच्या संयुक्त विद्यमाने <br /> विश्वातले पहिले <br /> श्री
                    शिव चरित्र साहित्य संमेलन
                  </h2>
                </div>
                <div className={cls.eventdetails}>
                  <p>
                    दिनांक : २६ जुन ते २९ जुन २०२४ । स्थळ : नुतन मराठा
                    महाविद्यालयाचे प्रांगण, जळगांव
                  </p>
                </div>
                <div className={cls.highlights}>
                  <h4>प्रमुख आकर्षण....</h4>
                  <ul>
                    <li>शिवकाल अवतरणार आपल्या शहरात</li>
                    <li>७५ सरदार घराणी आणि असंख्य मावळे आपल्या सोबत</li>
                    <li>जागतिक स्तरावरील शिव चरित्र अभ्यासकांची उपस्थिती</li>
                    <li>
                      सातारा, तंजावर, नागपूर गादीचे वारस येणार आपल्या शहरात
                    </li>
                  </ul>
                </div>
                <div>
                  <h3>
                    शस्त्र प्रदर्शन, नाणी प्रदर्शन, <br />
                    चित्र प्रदर्शन, पुस्तक प्रदर्शन, <br />
                    शस्त्र प्रशिक्षण, शाहिरी पोवाडे, जागरण गोंधळ, कवी संमेलन
                  </h3>
                </div>

                {/* to be done from here */}
                {/* <div className={cls.contacts}>
                  <p>प्राचार्य. डॉ. लक्ष्मणराव देशमुख</p>
                  <p>
                    निमंत्रक श्री. रवींद्र पाटील नियंत्रक सौ. भारती साठे सचिव
                    श्री. विनय डांगे - 9404050969 7887337332 9860475580
                    9833366181
                  </p>
                  <p>
                    प्रा.डॉ.आर.बी.देशमुख जळगांव श्री. दिनेश नाईक, अमळनेर श्री.
                    संदीप तापकीर, पुणे प्रा.डॉ. पल्लवी ताजने, वरोरा 7020539833
                    9226785795 9850179421 9503509006
                  </p>
                  <p>
                    श्री. अॅड. शंकर जाधव सं. नगर प्रा.राजेश अढाऊ, अकोट प्रा.डॉ.
                    माधुरी पाटील, जळगांव श्री. पराग लिमये, मुंबई 94222 91964
                    9881313239 9404594441 9987565738
                  </p>
                  <p>*संपर्क</p>
                </div>
              </div>
            </div> */}
            
          </div>

          <div className={cls.box}>
            <DateButton
              name="संमेलनाविषयी...🔻"
            >
              जगाच्या पाठीवरती शिवराय छत्रपती जाहले ही साधी गोष्ट जाहली नाही.
              शतकानुशतकं काळ्याकभिन्न काळाच्या पटलावरती ६ जुन १६७४ म्हणजेच
              ज्येष्ठ शुद्ध त्रयोदशी शालिवाहन शके १५९६. रोजी रायगडावर राजांनी
              राजाभिषेक करवून घेतला आणि जगाच्या पाठीवर हिंदू राष्ट्राची
              मुहूर्तमेढ केली.गुलामगिरी आणि अत्याचाराने खचलेल्या रयतेमध्ये एक
              नवा आशावाद पेरून या एकाच क्षणाने भारतीय संस्कृतीचे पुनरुत्थान सुरू
              झाले. छत्रपतींनी मातीतून माणसं उभी केली. आणि त्याच माणसाच्या हातून
              अचाट असा पराक्रम घडवून आणला. जगाच्या पाठीवरती १६४५ ते १६८० हा
              ऊणेपुरे ३५ वर्षाच्या काळ.पण याच काळात राष्ट्रासाठी आणि
              शिवरायांच्या निष्टे पायी लोक मारायला आणि मरायला तयार होते. तो एक
              जगाच्या पाठीवरती सुवर्णकाळ होता. काय नव्हते तिथे. आर्थिक सुबत्ता
              होती.सामाजिक सरक्षण होते.नैतिक पाठबळ होते,धर्माचे अधिष्ठान होते,
              देवाचे महत्त्व होते आणि देशाविषयी निष्ठा होती. हाच वास्तववादी
              इतिहास आम्हाला पुढच्या पिढीला हस्तांतरीत करायचा आहे याच काळात
              कलेची सुबत्ता होति. शिवरायांचे एकच चरित्र अवघ्या सृष्टीला पुरून
              उरले आहे.आणि अवघ्या आसमंतात भरून पावले आहे. त्या शिवप्रभूच्या
              चरीत्र साहित्यांचे संमेलन म्हणजे नवतरुण आणि भविष्यातील पिढीला
              सकारात्मक दृष्टीने पुण्य पुरुषाच्या चरित्राचे अवलोकन व्हावे हाच
              एकमेव उद्देश आहे.या संमेलनास बारा मावळ प्रांतातील नद्या आणि गड
              देवता येणार आहेतच , शिवनेरीची मती आणि रायगडाची माती येणार आहेच,
              आपणही याल याच अपेक्षेने आपण सगळे मिळून पुन्हा एकदा शिवविचारांचा
              जागरणाचा गोंधळ घालुया. उदे ग अंबे ऊदे
            </DateButton>
            <DateButton name="इतिहास प्रबोधन संस्थेविषयी.... 🔻">
              इतिहास प्रबोधन संस्था महाराष्ट्र. ही महाराष्ट्र राज्यातील छत्रपती
              शिवाजी महाराज यांच्या विषयात काम करणारी एक अग्रगण्य संस्था असून या
              संस्थेच्या माध्यमातून सन २०१७ पासून १६३० ते १६८० या काळातील घटना
              क्रमांक अभ्यास करणे हे एकमेव उद्दिष्ट आहे २०२१ ते २०२२ या
              वर्षामध्ये इतिहास प्रबोधन संस्थेच्या माध्यमातून सलग ५८ एतीहासिक
              विषयावर व्याख्यान घेण्याचा उपक्रम यशस्वी केलेला आहे. विविध
              चर्चासत्र, परिसंवाद अभ्यास मंडळ, अभ्यास केंद्र, व्याख्याने,
              प्रवचने, सहली, गडांविषयी प्रबोधन या आणि अशा तत्सम विषयांचा अभ्यास
              संस्थेच्या माध्यमातून केला जातो, याच संस्थेच्या माध्यमातून किल्ले
              रायगडचा २७०० वर्षांपूर्वीचा इतिहास वेध रायगडाचा या ग्रंथाच्या
              माध्यमातून समाजाच्या पटलावरती एक दस्तऐवज मांडण्यात यश आले आहे. याच
              संस्थेच्या माध्यमातून शिवप्रताप नावाची छत्रपती शिवाजी महाराजांच्या
              चरित्रावरची दैनंदिनी प्रकाशित करण्याचा मान आपण मिळवला आहे तसेच
              श्री शिवचरित्र पुष्पांजली या ग्रंथामध्ये छत्रपतींच्या विविध अशा ९८
              ध्येय धोरण आणि नीती याविषयी लेखाचे संपादन देखील केलेले आहे. इतिहास
              क्षेत्रात काम करीत असताना विविध पुरस्कार आणि वक्ता प्रशिक्षण
              कार्यशाळा असे देखील उपक्रम आयोजित केले जातात, यावर्षी छत्रपती
              शिवाजी महाराजांच्या राज्याभिषेकाच्या साडेतीनशे वर्षाच्या औचित्याने
              शिवचरित्र साहित्य संमेलन आयोजित करण्याचे आहे. इतिहास अभ्यास आणि
              संशोधन मंडळ, सुरत आणि पन्हाळा या दोन एतीहासिक क्षेत्राचा
              अभ्यासपूर्ण दस्तऐवज तयार करण्याचे उद्दिष्ट आहे, छत्रपतींची
              युद्धनीती आणि छत्रपतींचे भावविश्व या विषयाचे संस्थेच्या माध्यमातून
              प्रकाशन मार्गावर आहे.अपरिचित मावळे हा एक अभिनव उपक्रम संस्था
              राबवित आहे. सांप्रत काळातील सरदार घराणी यांना एकाच व्यासपीठावर
              आणण्याचा मानस संस्थेने उदृत केलेला आहे.
            </DateButton>

            <DateButton name="नूतन मराठा महाविद्यालयविषयी... 🔻">
              जळगाव जिल्हा मराठा विद्या प्रसारक समाज संचालित १९७२ साली जळगाव
              येथे पहिले कला, वाणिज्य आणि विज्ञान महाविद्यालय स्थापन केले आहे.
              समाजाच्या दूरदर्शी व्यक्तींच्या मार्गदर्शनाने आणि योग्य
              शिक्षकांच्या प्रयत्नाने कालांतराने महाविद्यालयाची प्रगती झाली आहे.
              नवीन विभाग आणि नवीन अभ्यासक्रमांची भर घालून आणि त्याची व्याप्ती
              वाढवून संपूर्ण जळगाव जिल्ह्यात नावलौकिक मिळवला आणि नूतन मराठा
              कॉलेज म्हणून ओळखले जात आहे. कालांतराने असे काही द्रष्टे होते
              ज्यांनी देणगीच्या रूपात पायाभूत सुविधांच्या उभारणीसाठी भरीव आर्थिक
              मदत केली, म्हणून त्यांची नावे विविध विद्याशाखांना दिली जातात. या
              महाविद्यालयाचे नोंदणीकृत नाव श्री एस. एस. पाटील कला, श्री भाऊसाहेब
              टी. टी. साळुंखे वाणिज्य आणि श्री जी. आर. पंडित सायन्स कॉलेज, जळगाव
              हे महाविद्यालय क ब चौ उत्तर महाराष्ट्र विद्यापीठाशी संलग्न आहे आणि
              या महाविद्यालयात ११ वी ते M. A., M. Com, M.Sc पर्यंतचे शिक्षण दिले
              जाते. या महाविद्यालयात भौतिकशास्त्र, रसायनशास्त्र, वनस्पतीशास्त्र,
              सूक्ष्मजीवशास्त्र, प्राणीशास्त्र, गणित, संगणक विज्ञान,
              इलेक्ट्रॉनिक इत्यादी विषयांचा पदवी अभ्यासक्रम चालवला जातो.
              इंग्रजी, हिंदी, मराठी, उर्दू आणि सर्व सामाजिक शास्त्रांमध्ये
              स्वतंत्र वाणिज्य विद्याशाखा उपलब्ध आहेत. .महाविद्यालयात मध्ये सर्व
              अभ्यासक्रम पूर्ण करण्यासाठी योग्य शिक्षक कर्मचारी आहेत आणि बहुतेक
              शिक्षक एम. फिलचे मार्गदर्शनही करत आहेत. पीएच.डी. देखील
              मार्गदर्शक.आहेत हे महाविद्यालय शहराच्या मध्यभागी ०८ एकर जागेत
              वसलेले असून त्याचे क्षेत्रफळ ८४८५ चौरस मीटर आहे. महाविद्यालयात
              पस्तीस वर्ग खोल्या, विज्ञान, संगणक विज्ञान, मानसशास्त्र, इंग्रजी
              भाषा प्रयोगशाळा, भूगोल, वाणिज्य सामाजिक विज्ञान विभाग, जिमखाना,
              अंशतः स्वयंचलित केंद्रीय ग्रंथालय, NSS, NCC, NAAC, IQAC कार्यालये,
              प्राचार्य, उपप्राचार्य, प्रशासकीय कार्यालये आहेत. , जेंट्स आणि
              लेडीज स्टाफ रूम आणि मुलींसाठी लेडीज रूम. या व्यतिरिक्त
              ऑडिओ-व्हिज्युअल एड्स, ओएचपी, एलसीडी, पॉवर जनरेटर, इन्व्हर्टर,
              सुरक्षित आणि शुद्ध पिण्याचे पाणी, विद्यार्थी आणि शिक्षकांसाठी वाहन
              पार्किंग, खेळाचे मैदान, इनडोअर आणि आउटडोअर गेम्ससाठी सुविधा इ.
              उपलब्ध आहेत
            </DateButton>
            <DateButton name="आयोजन समिति: 🔻">
              <p>
                स्वागताध्यक्ष:- श्री सुरेश दामू भोळे (राजू मामा भोळे) आमदार
                जळगाव
              </p>
              <p>स्वागत सचिव:- श्री किरण बच्छाव जळगाव</p>
              <p>निमंत्रक:- प्राचार्य. डॉ लक्ष्मणराव देशमुख जळगाव</p>
              <p>नियंत्रक :- श्री रवींद्र पाटील पाचोरा</p>
              <p>सचिव:- सौ भारती साठे यावल</p>
              <p>अध्यक्ष:- श्री विनय डांगे डोंबवली</p>
              <p>प्राचार्य डॉ.व्ही.आर.पाटील पारोळा</p>
              <p>प्राचार्य डॉ मनोहर पाटील धुळे</p>
              <p>प्राचार्य टी आर पाटील छ. स. नगर </p>
              <p>प्राचार्य डॉ आर एस पाटील शहादा</p>
              <p>प्राचार्य डॉ एस आर जाधव चाळीसगाव</p>
              <p>प्राचार्य डॉ लता मोरे खिरोदा</p>
              <p>प्राचार्य डॉ.महेंद्र सूर्यवंशी नंदुरबार</p>
              <p>प्राचार्य डॉ प्रीती अग्रवाल (रायसोनी महा जळगाव)</p>
              <p>प्राचार्य डॉ सि पी सावंत अक्कलकुवा </p>
              <p>प्राचार्य डॉ वैशाली पाटील शिरपूर</p>
              <p>प्राचार्य डॉ प्रमोद पवार जळगाव </p>
              <p>प्राचार्य डॉ अनिल पाटील अमळनेर </p>
              <p>श्री सुनील व्हि.गरुड जळगाव </p>
              <p>प्रा.आर.बी.देशमुख जळगाव</p>
              <p>दिनेश नाईक अमळनेर</p>
              <p>राहुल पाटील पारोळा</p>
              <p>प्रा. डॉ. के बी पाटील जळगाव</p>
              <p>प्रा.राजेश अढावू अकोट</p>
              <p>प्रा.दत्ता देशमुख टेंभुर्णी</p>
              <p>प्रा. विनोद रायपूरे चोपडा</p>
              <p>श्री. प्रविण सूशीर जामनेर</p>
              <p>प्रा. डॉ. माधुरी पाटील जळगाव</p>
            </DateButton>
          </div>

          <div
            style={{
              padding: "0 0 3rem 0",
              justifyContent: "center",
              display: "flex",
              gap: "2rem",
            }}
          >
            {/* <a href={aboutPDF} download="AboutABPV.pdf">
              <button className="btn btn-lg btn-success">
                Want to know more about us?
              </button>
            </a>
            <a
              href={"https://youtu.be/rk0uqGGoQKo"}
              target="_blank"
              rel="noreferrer"
            >
              <button className="btn btn-lg btn-primary">Instructions</button> */}
            {/* </a> */}
          </div>
        </div>
      </section>
    </>
  );
};

export default HeroSection;
