/* eslint-disable jsx-a11y/no-distracting-elements */
import React, { useEffect, useState } from "react";

import Footer from "../components/Footer/Footer";
import Header from "../components/Header/Header";
import HeroSection from "../components/HeroSection/HeroSection";
import Sponsors from "../components/Sponsors/Sponsors";

import axios from "axios";

const Landing = () => {
  const [banner, setBanner] = useState("Loading...");

  useEffect(() => {
    const getData = async () => {
      try {
        const req = await axios.get(
          `${process.env.REACT_APP_BACKEND}getContent`
        );
        // console.log(req);
        const data = req.data;
        // console.log(data);
        setBanner(data.content);
      } catch (error) {
        console.error(error);
        setBanner("Loading...");
      }
    };

    getData();
  }, []);

  return (
    <>
      <Header />
      <div className={"container mt-3"}>
        <marquee className="alert alert-success" role="alert">
          {banner}
        </marquee>
      </div>
      <HeroSection />
      <Sponsors />
      <Footer />
    </>
  );
};

export default Landing;
