import Footer from "../components/Footer/Footer";
import Header from "../components/Header/Header";
import HeroTimetable from "../components/HeroTimetable/HeroTimetable";
import Schedule from "../components/Schedule/Schedule";

const Timetable = () => {
  return (
    <>
      <Header />
      <HeroTimetable />
      {/* <Schedule /> */}
      <Footer />
    </>
  );
};

export default Timetable;
