import React, { useEffect } from "react";

import Footer from "../components/Footer/Footer"; // Adjust import paths as necessary
import Header from "../components/Header/Header"; // Adjust import paths as necessary

const Suggestions = () => {
  return (
    <>
      <Header />
      <iframe
        src="https://docs.google.com/forms/d/e/1FAIpQLSdQyQl4AuKdfC8h3A9bjdi6_maUBVxLvRIVxEA4fZtt7ZXdHg/viewform?embedded=true"
        width="100%"
        height="1285"
        frameborder="0"
        marginheight="0"
        marginwidth="0"
      >
        Loading…
      </iframe>
      <Footer />
    </>
  );
};

export default Suggestions;
