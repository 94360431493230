import Footer from "../components/Footer/Footer";
import GalleryComp from "../components/GalleryComp/GalleryComp";
import Header from "../components/Header/Header";

const Gallery = () => {
  return (
    <>
      <Header />
      <GalleryComp />
      <Footer />
    </>
  );
};

export default Gallery;
