import Footer from "../components/Footer/Footer";
import Header from "../components/Header/Header";
import HeroArrangements from "../components/HeroArrangements/HeroArrangements";

const Arrangements = () => {
  return (
    <>
      <Header />
      <HeroArrangements />
      <Footer />
    </>
  );
};

export default Arrangements;
